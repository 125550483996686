<template>
  <div>
    <span class="card-title">Beheer + Beveiliging</span>
    <p class="month-description">{{duration}} maanden</p>
    <span class="card-price">&euro;{{price}}/jaar</span>
    <button class="card-choose" @click="select()">Kies</button>
    <div class="card-description-header">Applicaties</div>
      <div class="description-entry">Website</div>
      <div class="description-entry">App</div>
      <div class="card-description-header">Nauwkeurigheid</div>
      <div class="description-entry">1 meter</div>
      <div class="card-description-header">Alarmen</div>
      <div class="description-entry">Accu laag</div>
      <div class="description-entry">Accu-sabotage</div>
      <div class="description-entry">Verscherpt toezicht</div>
      <div class="description-entry">Geofence</div>
      <div class="description-entry">Alarm centrale</div>
  </div>
</template>

<script>
export default {
    props: ["duration","price", "id"],
    methods: {
        select(){
            this.$emit('selected', this.id);
        }
    }
};
</script>