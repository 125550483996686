<template>
  <div>
    <span class="card-title">Demo Abonnement</span>
    <p class="month-description">{{duration}} maanden</p>
    <span class="card-price">&euro;{{price}}/jaar</span>
    <button class="card-choose" @click="select()">Kies</button>
    <div class="card-descriptions">
      <div class="card-description-header">Demo</div>
      <div class="description-entry">Dit abonnement bestaat niet.</div>
      <div class="card-description-header">Applicaties</div>
      <div class="description-entry">Website</div>
      <div class="description-entry">App</div>
      <div class="card-description-header">Extra</div>
      <div class="description-entry">Alarmmeldingen</div>
      <div class="description-entry">Rapportages</div>
      <div class="description-entry">Onderhoudsmeldingen</div>
    </div>
  </div>
</template>

<script>
export default {
    props: ["duration","price", "id"],
    methods: {
        select(){
            this.$emit('selected', this.id);
        }
    }
};
</script>