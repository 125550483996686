<template>
  <div id="main">
    <div id="error-popup" :class="{'hidden': !error, 'show': error}" @click.self="closePopup">
      <div id="error-popup-content">
        <h1>Er is iets fout gegaan</h1>
        Neem alstublieft contact op met {{company.Phonenumber}} en meld de code: {{error}}
      </div>
    </div>
    <div id="error-popup" :class="{'hidden': !validationErrors, 'show': validationErrors}" @click.self="closeValidationPopup">
      <div id="error-popup-content">
        <h1>Corrigeer de volgende fouten om door te gaan:</h1>
        <ul>
          <li v-for="validationError in validationErrors" v-bind:key="validationError" class="error">{{ validationError }}</li>
        </ul>
      </div>
    </div>
    <div id="form">
      <div id="progress">
        <template v-for="(step, index) in steps">
          <router-link
            class="progress-checkmark"
            :class="{'progress-current': stepNumber === index, 'progress-checked': maxStepNumber > index, 'disabled': maxStepNumber < index, 'progress-open': maxStepNumber >= index}"
            v-bind:key="index"
            :to="maxStepNumber >= index ? step.link: ''"
          >
            <div class="progress-number" v-if="maxStepNumber > index">&#x2714;</div>
            <div class="progress-number" v-else>{{index + 1}}</div>
            <span>{{step.name}}</span>
          </router-link>
        </template>
      </div>
      <router-view @changeStep="changeStep" id="content-main" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      stepNumber: 0,
      maxStepNumber: 0,
      steps: [
        { name: "Voertuig", link: "/Vehicle"},
        { name: "Gegevens", link: "/Login" },
        { name: "Abonnement", link: "/Plan" },
        { name: "Betaling", link: "/Payment" },
        { name: "Controle", link: "/Check" },
      ],
    };
  },
  methods: {
    changeStep(val) {
      this.stepNumber = val;
      if (val > this.maxStepNumber) {
        this.maxStepNumber = val;
      }
    },
    closePopup() {
      this.$store.commit("clearError");
    },
    closeValidationPopup() {
      this.$store.commit("clearValidationErrors");
    },
  },
  computed: mapState(["error", "validationErrors", "company"]),
};
</script>

<style scoped lang="scss">
#main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $light-gray;
}

#form {
  max-width: 1200px;
  width: 100%;
  min-width: 320px;
  box-sizing: border-box;
  background-color: $white;
  display: flex;
  flex-direction: column;
  position: relative;
}

#content-main {
  display: flex;
  flex-grow: 1;
  min-height: 0;
}

#progress {
  box-sizing: border-box;
  padding: 15px 25px;
  border-bottom: 1px solid $light-gray;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: $white;
}

.progress-checkmark {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 5px;
  font-weight: bold;
  text-decoration: none;
}

.progress-checkmark:visited > span {
  color: $primary;
}

.progress-checkmark.disabled {
  cursor: default;
}

.progress-number {
  color: $white;
  background-color: $light-gray;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-right: 5px;
}

.progress-checked {
  color: $primary;
}

.progress-checked > .progress-number,
.progress-open > .progress-number {
  background-color: $primary;
}

.progress-current {
  border-bottom: 1px solid $secondary;
  color: $primary;
}

.progress-current > .progress-number {
  background-color: $primary;
}

@media only screen and (max-width: 580px) {
  #progress > a:not(.progress-current) {
    display: none;
  }
}

@media only screen and (min-width: 1200px) {
  #main {
    height: 100vh;
  }

  #form {
    min-height: 0;
    height: 90%;
    box-shadow: 0px 7px 5px 1px $shadow;
  }
}

#error-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  &.hidden {
    background-color: transparent;
    transition: background-color 0.1s;
    pointer-events: none;
  }

  &.show {
    background-color: #00000060;
    transition: background-color 0.1s;
  }
}

#error-popup-content {
  background-color: $white;
  max-width: 500px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  overflow: hidden;

  h1 {
    margin-bottom: 5px;
    font-size: 16pt;
  }
}

#error-popup.hidden > #error-popup-content {
  height: 0px;
  padding: 0px 25px;

  transition: all 0.1s ease;
  transition-property: box-shadow, height, padding;
}

#error-popup.show > #error-popup-content {
  padding: 25px;
  box-shadow: 0px 7px 5px 1px $shadow;

  transition: all 0.1s ease;
  transition-property: box-shadow, height, padding;
}
</style>