<template>
  <div>
    <form @submit.prevent="next">
      <div class="column">
        <div class="check-block">
          <h3>Persoonsgegevens</h3>
          <div class="check-row">
            <span>Contactpersoon</span>
            <span>{{input.PersonData.FirstName}} {{input.PersonData.LastName}}</span>
          </div>
          <div class="check-row">
            <span>Emailadres</span>
            <span>{{input.PersonData.Email}}</span>
          </div>
          <div class="check-row">
            <span>Adres</span>
            <span>{{input.PersonData.Street}} {{input.PersonData.HouseNumber}}, {{input.PersonData.ZipCode}} {{input.PersonData.City}}</span>
          </div>
          <div class="check-row">
            <span>Telefoonnummer</span>
            <span>{{input.PersonData.PhoneNumber}}</span>
          </div>
          <div class="check-row">
            <span>Extra Telefoonnummer</span>
            <span>{{input.PersonData.PhoneNumberExtra}}</span>
          </div>
        </div>
        <div class="check-block">
          <h3>Alarm contactpersoon</h3>
          <div class="check-row">
            <span>Contactpersoon</span>
            <span>{{input.Alarm1.FirstName}} {{input.Alarm1.LastName}}</span>
          </div>
          <div class="check-row">
            <span>Telefoonnummer</span>
            <span>{{input.Alarm1.PhoneNumber1}}</span>
          </div>
          <div class="check-row">
            <span>Extra Telefoonnummer</span>
            <span>{{input.Alarm1.PhoneNumber2}}</span>
          </div>
        </div>
        <div class="check-block">
          <h3>Alarm contactpersoon 2</h3>
          <div class="check-row">
            <span>Contactpersoon</span>
            <span>{{input.Alarm2.FirstName}} {{input.Alarm2.LastName}}</span>
          </div>
          <div class="check-row">
            <span>Telefoonnummer</span>
            <span>{{input.Alarm2.PhoneNumber1}}</span>
          </div>
          <div class="check-row">
            <span>Extra Telefoonnummer</span>
            <span>{{input.Alarm2.PhoneNumber2}}</span>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="check-block">
          <h3>Abonnement</h3>
          <div class="check-row">
            <span>Type</span>
            <span>{{input.Plan.Type}}</span>
          </div>
          <div class="check-row">
            <span>Looptijd</span>
            <span>{{input.Plan.Duration}} maanden</span>
          </div>
          <div class="check-row">
            <span>Prijs per jaar exc. BTW</span>
            <span>&euro;{{input.Plan.PriceExVat}}</span>
          </div>
          <div class="check-row">
            <span>Prijs per jaar inc. BTW</span>
            <span>&euro;{{input.Plan.PriceIncVat}}</span>
          </div>
        </div>
        <div class="check-block">
          <h3>Betalingsgegevens</h3>
          <div class="check-row">
            <span>Rekeninghouder</span>
            <span>{{input.Payment.AccountHolder}}</span>
          </div>
          <div class="check-row">
            <span>IBAN</span>
            <span>{{input.Payment.Iban}}</span>
          </div>
          <div class="check-row">
            <span>Betaalwijze</span>
            <span>{{input.Payment.PaymentMethod}}</span>
          </div>
        </div>
        <h3>Voorwaarden</h3>
        <div class="check-conditions">
          <label class="container">
            Ik verklaar de gegevens volledig, juist en naar waarheid te hebben ingevuld.
            <input type="checkbox" v-model="finalCheck" />
            <span class="checkmark"></span>
          </label>
        </div>
        <div id="check-footer">
          <button @click="back">Terug</button>
          <button class="red-button">Afronden</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      finalCheck: false,
      input: {
        PersonData: {
          FirstName: "-",
          LastName: "-",
          Email: "-",
          City: "-",
          Street: "-",
          ZipCode: "-",
          HouseNumber: "-",
          PhoneNumber: "-",
          PhoneNumberExtra: "-"
        },
        Alarm1: {
          FirstName: "-",
          LastName: "-",
          PhoneNumber1: "-",
          PhoneNumber2: "-"
        },
        Alarm2: {
          FirstName: "-",
          LastName: "-",
          PhoneNumber1: "-",
          PhoneNumber2: "-"
        },
        Plan: {
          Type: "-",
          Duration: "-",
          PriceExVat: "-",
          PriceIncVat: "-"
        },
        Payment: {
          AccountHolder: "-",
          Iban: "-"
        }
      }
    };
  },
  created() {
    this.$emit("changeStep", 4);
    
    // Demo mode: don't load already filled out activation data because we don't have an actual activation.
    if (this.$cookies.get("activation-code") === "demo"){
      return;
    }

    this.$api.get(`Check/Load/${process.env.VUE_APP_TITLE}`).then(response => {
      this.input = response.data;
    });
  },
  methods: {
    back() {
      this.$router.push("/payment");
    },
    next(e) {
			this.$store.commit("clearValidationErrors");
			let validationErrors = [];

			if (!this.finalCheck) {
				validationErrors.push(`Verklaar dat de gegevens volledig, juist en naar waarheid zijn ingevuld om een abonnement af te kunnen sluiten.`);
			}

			if (!validationErrors.length) {
				// Demo mode: don't save, just go to the next page.
				if (this.$cookies.get("activation-code") === "demo"){
					this.$router.push("/done");
					return;
        }

        this.$api.post(`Submit/${process.env.VUE_APP_TITLE}`, {}).then(() => {
          this.$router.push("/done");
          
        });
        return;

      }
      
			this.$store.commit("setValidationErrors", validationErrors);
			e.preventDefault();
    }
  }
};
</script>

<style scoped lang="scss">
#content-main > form {
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  width: 100%;
}

.column {
  padding: 25px 60px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 823px) {
  .column {
    padding: 25px;
  }
}

.check-row {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.check-row > span:first-child {
  font-weight: bold;
}

.check-block {
  margin-bottom: 30px;
}

#check-footer {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

#check-footer > button {
  align-self: flex-end;
}

.red-button {
  background-color: $secondary;
  border: none;
  color: $secondary-button;
}

.red-button:hover {
  background-color: $secondary-light;
}

.check-conditions {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
