import Vue from 'vue'
import App from './App.vue'
import { Api } from './scripts/api';
import Router from './scripts/router';
import store from './scripts/store';

Vue.config.productionTip = false;
Vue.prototype.$api = Api;

new Vue({
  render: h => h(App),
  router: Router,
  store: store
}).$mount('#app')
