<template>
  <router-view id="main" />
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}
</style>

<style>
#main-code, #main-done {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 320px;
  width: 100%;
  max-width: 500px;
  margin-bottom: 30px;
}

#img-wrapper {
  width: 100%;
  padding: 5vh 50px;
  box-sizing: border-box;
}

#logo-main {
  width: 100%;
  height: auto;
}
</style>