import Axios from 'axios';
import NProgress from 'nprogress';
import VueCookies from 'vue-cookies';
import Vue from 'vue';
import Router from './router';
import store from './store';

Vue.use(VueCookies);

const Api = Axios.create({
    baseURL: process.env.VUE_APP_API,
    headers: { "Content-Type": "application/json;charset=UTF-8" }
});

Api.interceptors.request.use(config => {
    NProgress.start();

    var code = Vue.prototype.$cookies.get("activation-code");
    if (code) {
        config.headers.common["ActivationCode"] = code;
    }

    return config;
});

Api.interceptors.response.use(response => {
    NProgress.done();
    return Promise.resolve(response);
}, error => {
    NProgress.done();

    if (error.response.status !== 401 || error.response.config.url == "User/Login") {
        store.commit('setError', error.response.data.Message);
        return Promise.reject(error);
    } else {
        Router.push('/Code');
        return Promise.reject(error);
    }
});

export { Api }