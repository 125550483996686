import Vue from 'vue';
import Vuex from 'vuex';
import { Api } from './api';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        error: null,
        validationErrors: null,
        company: {
            Name: null,
            Phonenumber: null
        }
    },
    mutations: {
        setError(state, error) {
            state.error = error;
        },
        clearError(state) {
            state.error = null;
        },
        setValidationErrors(state, validationErrors) {
            state.validationErrors = validationErrors;
        },
        clearValidationErrors(state) {
            state.validationErrors = null;
        },
        setCompany(state, payload) {
            state.company = payload;
        }
    },
    actions: {
        loadCompanyData({ commit }) {
            Api.get(`Data/${process.env.VUE_APP_TITLE}`).then(response => {
                commit('setCompany', response.data);
                document.title = `Activatie portal - ${response.data.Name}`;
            });
        }
    }
});

export default store;