import Code from '@/components/Code';
import Vehicle from '@/components/Vehicle';
import Login from '@/components/Login';
import Plan from '@/components/Plan';
import Payment from '@/components/Payment';
import Check from '@/components/Check';
import Done from '@/components/Done';

var Routes = [
    {path: '/Code', component: Code, meta: {template: 'clean', anon: true}},
    {path: '/Vehicle', component: Vehicle},
    {path: '/Login', component: Login},
    {path: '/Plan', component: Plan},
    {path: '/Payment', component: Payment},
    {path: '/Check', component: Check},
    {path: '/Done', component: Done, meta: {template: 'clean'}},
    {path: '*', redirect: '/Code'}
];

export default Routes;