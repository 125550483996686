<template>
  <div>
    <div id="main-done">
      <div id="img-wrapper">
        <img id="logo-main" :src="require(`../images/${logoName}/logo.png`)" />
      </div>
      <h3>Gefeliciteerd!</h3>
      <p>Hartelijk dank voor uw aankoop! Wij wensen u veel en veilig rijplezier toe.</p>
      <p>Een bevestigingsmail is verstuurd naar de contactpersoon.</p>
      <p class="team">Team {{company.Name}}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      business: process.env.VUE_APP_TITLE,
      logoName: process.env.VUE_APP_TITLE
    };
  },
  created() {
    this.$cookies.set("activation-code", "", 0);
  },
  computed: mapState(['company'])
};
</script>

<style scoped>
h3 {
  margin-bottom: 10px;
}

p {
  text-align: center;
  margin: 10px;
}

.team {
  font-weight: bold;
}
</style>