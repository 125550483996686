<template>
  <Clean v-if="$route.meta.template === 'clean'"/>
  <Default v-else/>
</template>

<script>
import Clean from "./layouts/Clean";
import Default from "./layouts/Default";

export default {
  name: "main",
  components: {
    Clean,
    Default
  },
  created(){
    this.$store.dispatch('loadCompanyData'); 
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900|Open+Sans:300,400&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif;
  color: $primary;
}

button {
  padding: 10px 25px;
  border: none;
  border: 1px solid $primary;
  background-color: $white;
  cursor: pointer;
}

button:hover {
  background-color: $light-gray;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $white;
}

::-webkit-scrollbar-thumb {
  background: $primary;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-light;
}

.error {
  color: $secondary;
  font-size: 11pt;
}

#mfselect {
  display: grid;
  grid-gap: 10px;
}

.mfselect-3 {
  grid-template-columns: auto auto auto 1fr;
}

.mfselect-2 {
  grid-template-columns: auto auto 1fr;
}

.select-value > label {
  padding: 5px 0;
  cursor: pointer;
}

.select-value > input {
  display: none;
}

input[type="radio"]:checked + label {
  border-bottom: 1px solid $secondary;
  font-weight: bold;
}

input {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid $white;
  padding: 5px;
  color: $white;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
}

.input-half {
  width: 45%;
  margin-right: 5%;
}

.input-quarter {
  width: 20%;
  margin-right: 5%;
}

.input-full {
  width: 95%;
}

.light-inputs input {
  color: $primary;
  border-bottom: 1px solid $primary;
}

input:focus,
.light-inputs input:focus {
  border: none;
  border-bottom: 1px solid $secondary;
  outline: none;
}

/*Checkbox*/
.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin: 12px 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: $white;
  border: 1px solid $primary;
}

.container:hover input ~ .checkmark {
  background-color: $light-gray;
}

.container input:checked ~ .checkmark {
  background-color: $white;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid $green;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-error {
  border-bottom: 1px solid $red !important;
}

/*Nprogress*/
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $secondary;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $secondary, 0 0 5px $secondary;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: $secondary;
  border-left-color: $secondary;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
