<template>
  <div>
    <div id="main-code">
      <div id="img-wrapper">
        <img id="logo-main" :src="require(`../images/${logoName}/logo.png`)" />
      </div>
      <h3>Activatie code</h3>
      <p class="error error-paragraph" v-if="error">{{ error }}</p>
      <form>
        <input
          id="first-input"
          class="code-input"
          maxlength="1"
          @focus="$event.target.select()"
          @input="$event.target.nextElementSibling.focus()"
          @keyup.enter="checkInput"
          v-model="inputs.one"
        />
        <input
          class="code-input"
          maxlength="1"
          @input="$event.target.nextElementSibling.focus()"
          @focus="$event.target.select()"
          @keyup.enter="checkInput"
          v-model="inputs.two"
        />
        <input
          class="code-input"
          maxlength="1"
          @input="$event.target.nextElementSibling.focus()"
          @focus="$event.target.select()"
          @keyup.enter="checkInput"
          v-model="inputs.three"
        />
        <input
          class="code-input"
          maxlength="1"
          @input="$event.target.nextElementSibling.focus()"
          @focus="$event.target.select()"
          @keyup.enter="checkInput"
          v-model="inputs.four"
        />
        <input
          class="code-input"
          maxlength="1"
          @input="$event.target.nextElementSibling.focus()"
          @focus="$event.target.select()"
          @keyup.enter="checkInput"
          v-model="inputs.five"
        />
        <input
          class="code-input"
          maxlength="1"
          @input="$event.target.nextElementSibling.focus()"
          @focus="$event.target.select()"
          @keyup.enter="checkInput"
          v-model="inputs.six"
        />
        <input
          class="code-input"
          maxlength="1"
          @input="$event.target.nextElementSibling.focus()"
          @focus="$event.target.select()"
          @keyup.enter="checkInput"
          v-model="inputs.seven"
        />
        <input
          class="code-input"
          maxlength="1"
          @input="checkInput"
          @keyup.enter="checkInput"
          v-model="inputs.eight"
        />
      </form>
      <p class="main-subtext">
        Voer alstublieft de activatie code in die u ontvangen heeft van uw
        dealer.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {     
    return {
      logoName: process.env.VUE_APP_TITLE,
      inputs: {
        one:    this.$route.query.code && this.$route.query.code[0] ? this.$route.query.code[0] : null,
        two:    this.$route.query.code && this.$route.query.code[1] ? this.$route.query.code[1] : null,
        three:  this.$route.query.code && this.$route.query.code[2] ? this.$route.query.code[2] : null,
        four:   this.$route.query.code && this.$route.query.code[3] ? this.$route.query.code[3] : null,
        five:   this.$route.query.code && this.$route.query.code[4] ? this.$route.query.code[4] : null,
        six:    this.$route.query.code && this.$route.query.code[5] ? this.$route.query.code[5] : null,
        seven:  this.$route.query.code && this.$route.query.code[6] ? this.$route.query.code[6] : null,
        eight:  this.$route.query.code && this.$route.query.code[7] ? this.$route.query.code[7] : null,
      },
      error: '',
    };
  },
  created() {
    if (this.inputs.eight) {
      this.checkInput();
    } else {
      this.$cookies.set("activation-code", "", 0);
    }
  },
  methods: {
    checkInput() {
      this.error = "";

      // Demo mode: you're allowed to see everything, but there should not be any interaction with the server
      // (especially saving the activation). Front-end should keep working like it should, validations and all.
      if (`${this.inputs.one}${this.inputs.two}${this.inputs.three}${this.inputs.four}`.toLowerCase() == "demo") {
        this.$cookies.set("activation-code", "demo", 0);
        this.$router.push("/Vehicle");
        return;
      }

      if (
        this.inputs.one &&
        this.inputs.two &&
        this.inputs.three &&
        this.inputs.four &&
        this.inputs.five &&
        this.inputs.six &&
        this.inputs.seven &&
        this.inputs.eight
      ) {
        this.$api
          .get(
            `code/${this.inputs.one}${this.inputs.two}${this.inputs.three}${this.inputs.four}${this.inputs.five}${this.inputs.six}${this.inputs.seven}${this.inputs.eight}`
          )
          .then((response) => {
            this.$cookies.set("activation-code", response.data.Token, 0);
            if (response.data.Step) {
              switch (response.data.Step) {
                case 1:
                  this.$router.push("/Vehicle");
                  return;
                case 2:
                  this.$router.push("/Plan");
                  return;
                case 3:
                  this.$router.push("/Payment");
                  return;
                case 4:
                  this.$router.push("/Check");
                  return;
              }
              if (response.data.Step > 4) {
                this.$router.push("/check");
                return;
              }
            }
            this.$router.push("/Vehicle");
          })
          .catch((error) => {
            this.inputs.one = null;
            this.inputs.two = null;
            this.inputs.three = null;
            this.inputs.four = null;
            this.inputs.five = null;
            this.inputs.six = null;
            this.inputs.seven = null;
            this.inputs.eight = null;

            if(error.response.data.Message){
              this.error = error.response.data.Message;
            } else {
              this.error = "Er is iets fout gegaan. Probeer het later opnieuw.";
            }

            document.getElementById("first-input").focus();
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
form {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 2%;
  margin: 2vh 0;
  padding: 5px;
  box-sizing: border-box;
}

.code-input {
  width: 100%;
  font-size: 14pt;
  padding: 10px;
  text-align: center;
  border: none;
  border: 1px solid $light-gray;
  border-radius: 3px;
  color: $primary;
  box-sizing: border-box;
}

.main-subtext {
  max-width: 400px;
  width: 100%;
  min-width: 320px;
  text-align: center;
}

.error-paragraph {
  margin: 10px 0 0 0;
}
</style>