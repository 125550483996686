import Vue from 'vue';
import VueRouter from 'vue-router';
import Routes from './routes';
import store from './store';

Vue.use(VueRouter);

const Router = new VueRouter({
    mode: "history",
    routes: Routes
});

Router.beforeEach((to, _from, next) => {
    store.commit('clearError');
    store.commit('clearValidationErrors');

    if (!to.meta.anon) {
        var code = Vue.prototype.$cookies.get("activation-code");
        if (code) {
            next();
        }
        else {
            Router.push("/Code");
        }
    }
    else {
        next();
    }
});

export default Router