<template>
  <div>
    <div id="signin-block" v-if="!isHidden" >
      <form id="signin-block-inner" @submit.prevent="login" v-if="!forgotPassword">
        <h3 id="signin-head">Inloggen</h3>
        <input placeholder="Gebruikersnaam" v-model="username" />
        <br />
        <input type="password" placeholder="Wachtwoord" v-model="password" />
        <br />
        <span class="error" v-if="error.login">Gebruikersnaam of wachtwoord incorrect.</span>
        <button class="login-button">Login</button>
        <a href="#" class="center-link" @click="forgotPasswordSwitch">Wachtwoord vergeten?</a>
        <a href="#" class="center-link new-customer" @click="gotoSignup">Nieuwe klant?</a>
      </form>
      <form id="signin-block-inner" @submit.prevent="forgotPasswordSubmit" v-else>
        <h3 id="signin-head">Wachtwoord vergeten</h3>
        <input placeholder="Gebruikersnaam" v-model="usernameForgotPassword" />
        <button>Versturen</button>
        <a href="#" class="center-link" @click="forgotPasswordSwitch">Mail ontvangen?</a>
      </form>
    </div>

    <div id="signup-block" class="light-inputs" ref="signupBlock">
      <h3 id="signup-head">Nieuwe klant</h3>
      <div class="mfselect mfselect-3">
        <div class="select-value">
          <input type="radio" value="Z" name="radio-payment-type" id="radio-business" v-model="input.PaymentInfo.PaymentType"  />
          <label for="radio-business">Zakelijk</label>
        </div>
        <div class="select-value" >
          <input type="radio" value="P" name="radio-payment-type" id="radio-private" v-model="input.PaymentInfo.PaymentType" selected/>
          <label for="radio-private">Privé</label>
        </div>
      </div>
      <div class="error input-full-grid" v-if="error.paymentType">Kies Zakelijk/Privé</div>
      <form @submit.prevent="signup" id="signup-block-inner">
        <div class="custom-select input-half-grid">
          <label>
            <select v-model="input.PaymentInfo.CountryCode"  v-if="input.PaymentInfo.PaymentType === 'Z'">
              <option selected value="NL" >Nederland</option>
              <option value="AF">Afghanistan</option>
              <option value="AX">Åland</option>
              <option value="AL">Albanië</option>
              <option value="DZ">Algerije</option>
              <option value="VI">Amerikaanse Maagdeneilanden</option>
              <option value="AS">Amerikaans-Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AO">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AQ">Antarctica</option>
              <option value="AG">Antigua en Barbuda</option>
              <option value="AR">Argentinië</option>
              <option value="AM">Armenië</option>
              <option value="AW">Aruba</option>
              <option value="AU">Australië</option>
              <option value="AZ">Azerbeidzjan</option>
              <option value="BS">Bahama's</option>
              <option value="BH">Bahrein</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BE">België</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia</option>
              <option value="BA">Bosnië en Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BV">Bouvet</option>
              <option value="BR">Brazilië</option>
              <option value="VG">Britse Maagdeneilanden</option>
              <option value="IO">Brits Indische Oceaanterritorium</option>
              <option value="BN">Brunei</option>
              <option value="BG">Bulgarije</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodja</option>
              <option value="CA">Canada</option>
              <option value="BQ">Caribisch Nederland</option>
              <option value="CF">Centraal-Afrikaanse Republiek</option>
              <option value="CL">Chili</option>
              <option value="CN">China</option>
              <option value="CX">Christmaseiland</option>
              <option value="CC">Cocoseilanden</option>
              <option value="CO">Colombia</option>
              <option value="KM">Comoren</option>
              <option value="CG">Congo-Brazzaville</option>
              <option value="CD">Congo-Kinshasa</option>
              <option value="CK">Cookeilanden</option>
              <option value="CR">Costa Rica</option>
              <option value="CU">Cuba</option>
              <option value="CW">Curaçao</option>
              <option value="CY">Cyprus</option>
              <option value="DK">Denemarken</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominicaanse Republiek</option>
              <option value="DE">Duitsland</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypte</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatoriaal-Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estland</option>
              <option value="ET">Ethiopië</option>
              <option value="FO">Faeröer</option>
              <option value="FK">Falklandeilanden</option>
              <option value="FJ">Fiji</option>
              <option value="PH">Filipijnen</option>
              <option value="FI">Finland</option>
              <option value="FR">Frankrijk</option>
              <option value="TF">Franse Zuidelijke Gebieden</option>
              <option value="GF">Frans-Guyana</option>
              <option value="PF">Frans-Polynesië</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgië</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GD">Grenada</option>
              <option value="GR">Griekenland</option>
              <option value="GL">Groenland</option>
              <option value="GP">Guadeloupe</option>
              <option value="GU">Guam</option>
              <option value="GT">Guatemala</option>
              <option value="GG">Guernsey</option>
              <option value="GN">Guinee</option>
              <option value="GW">Guinee-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haïti</option>
              <option value="HM">Heard en McDonaldeilanden</option>
              <option value="HN">Honduras</option>
              <option value="HU">Hongarije</option>
              <option value="HK">Hongkong</option>
              <option value="IE">Ierland</option>
              <option value="IS">IJsland</option>
              <option value="IN">India</option>
              <option value="ID">Indonesië</option>
              <option value="IQ">Irak</option>
              <option value="IR">Iran</option>
              <option value="IL">Israël</option>
              <option value="IT">Italië</option>
              <option value="CI">Ivoorkust</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="YE">Jemen</option>
              <option value="JE">Jersey</option>
              <option value="JO">Jordanië</option>
              <option value="KY">Kaaimaneilanden</option>
              <option value="CV">Kaapverdië</option>
              <option value="CM">Kameroen</option>
              <option value="KZ">Kazachstan</option>
              <option value="KE">Kenia</option>
              <option value="KG">Kirgizië</option>
              <option value="KI">Kiribati</option>
              <option value="UM">Kleine afgelegen eilanden van de Verenigde Staten</option>
              <option value="KW">Koeweit</option>
              <option value="HR">Kroatië</option>
              <option value="LA">Laos</option>
              <option value="LS">Lesotho</option>
              <option value="LV">Letland</option>
              <option value="LB">Libanon</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libië</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Litouwen</option>
              <option value="LU">Luxemburg</option>
              <option value="MO">Macau</option>
              <option value="MG">Madagaskar</option>
              <option value="MW">Malawi</option>
              <option value="MV">Malediven</option>
              <option value="MY">Maleisië</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="IM">Man</option>
              <option value="MA">Marokko</option>
              <option value="MH">Marshalleilanden</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritanië</option>
              <option value="MU">Mauritius</option>
              <option value="YT">Mayotte</option>
              <option value="MX">Mexico</option>
              <option value="FM">Micronesië</option>
              <option value="MD">Moldavië</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolië</option>
              <option value="ME">Montenegro</option>
              <option value="MS">Montserrat</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Namibië</option>
              <option value="NR">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="NI">Nicaragua</option>
              <option value="NC">Nieuw-Caledonië</option>
              <option value="NZ">Nieuw-Zeeland</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NU">Niue</option>
              <option value="MP">Noordelijke Marianen</option>
              <option value="KP">Noord-Korea</option>
              <option value="MK">Noord-Macedonië</option>
              <option value="NO">Noorwegen</option>
              <option value="NF">Norfolk</option>
              <option value="UG">Oeganda</option>
              <option value="UA">Oekraïne</option>
              <option value="UZ">Oezbekistan</option>
              <option value="OM">Oman</option>
              <option value="AT">Oostenrijk</option>
              <option value="TL">Oost-Timor</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau</option>
              <option value="PS">Palestina</option>
              <option value="PA">Panama</option>
              <option value="PG">Papoea-Nieuw-Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PN">Pitcairneilanden</option>
              <option value="PL">Polen</option>
              <option value="PT">Portugal</option>
              <option value="PR">Puerto Rico</option>
              <option value="QA">Qatar</option>
              <option value="RE">Réunion</option>
              <option value="RO">Roemenië</option>
              <option value="RU">Rusland</option>
              <option value="RW">Rwanda</option>
              <option value="BL">Saint-Barthélemy</option>
              <option value="KN">Saint Kitts en Nevis</option>
              <option value="LC">Saint Lucia</option>
              <option value="PM">Saint-Pierre en Miquelon</option>
              <option value="VC">Saint Vincent en de Grenadines</option>
              <option value="SB">Salomonseilanden</option>
              <option value="WS">Samoa</option>
              <option value="SM">San Marino</option>
              <option value="SA">Saoedi-Arabië</option>
              <option value="ST">Sao Tomé en Principe</option>
              <option value="SN">Senegal</option>
              <option value="RS">Servië</option>
              <option value="SC">Seychellen</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SH">Sint-Helena, Ascension en Tristan da Cunha</option>
              <option value="MF">Saint-Martin</option>
              <option value="SX">Sint Maarten</option>
              <option value="SI">Slovenië</option>
              <option value="SK">Slowakije</option>
              <option value="SD">Soedan</option>
              <option value="SO">Somalië</option>
              <option value="ES">Spanje</option>
              <option value="SJ">Spitsbergen en Jan Mayen</option>
              <option value="LK">Sri Lanka</option>
              <option value="SR">Suriname</option>
              <option value="SZ">Swaziland</option>
              <option value="SY">Syrië</option>
              <option value="TJ">Tadzjikistan</option>
              <option value="TW">Taiwan</option>
              <option value="TZ">Tanzania</option>
              <option value="TH">Thailand</option>
              <option value="TG">Togo</option>
              <option value="TK">Tokelau</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad en Tobago</option>
              <option value="TD">Tsjaad</option>
              <option value="CZ">Tsjechië</option>
              <option value="TN">Tunesië</option>
              <option value="TR">Turkije</option>
              <option value="TM">Turkmenistan</option>
              <option value="TC">Turks- en Caicoseilanden</option>
              <option value="TV">Tuvalu</option>
              <option value="UY">Uruguay</option>
              <option value="VU">Vanuatu</option>
              <option value="VA">Vaticaanstad</option>
              <option value="VE">Venezuela</option>
              <option value="AE">Verenigde Arabische Emiraten</option>
              <option value="US">Verenigde Staten</option>
              <option value="GB">Verenigd Koninkrijk</option>
              <option value="VN">Vietnam</option>
              <option value="WF">Wallis en Futuna</option>
              <option value="EH">Westelijke Sahara/SADR</option>
              <option value="BY">Wit-Rusland</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
              <option value="ZA">Zuid-Afrika</option>
              <option value="GS">Zuid-Georgia en de Zuidelijke Sandwicheilanden</option>
              <option value="KR">Zuid-Korea</option>
              <option value="SS">Zuid-Soedan</option>
              <option value="SE">Zweden</option>
              <option value="CH">Zwitserland</option>
            </select>
          </label>
        </div>

        <input placeholder="KvK nummer" class="input-half-grid" v-model="input.PaymentInfo.KvkNumber"
               v-if="input.PaymentInfo.PaymentType === 'Z'" />

        <input
            placeholder="Bedrijfsnaam"
            class="input-full-grid"
            v-if="input.PaymentInfo.PaymentType === 'Z'"
            v-model="input.CompanyName"
        />
        <div
            class="error input-full-grid"
            v-if="error.kvkInvalid"
        >Kvk nummer niet geldig</div>
        <div
            class="error input-full-grid"
            v-if="error.kvkRequired"
        >Kvk nummer is verplicht</div>

        <input placeholder="BTW nummer" class="input-half-grid" v-model="input.PaymentInfo.VatNumber"
                v-if="input.PaymentInfo.PaymentType === 'Z'" />

        <input placeholder="t.a.v." class="input-half-grid" v-model="input.PaymentInfo.AttentionOf"
               v-if="input.PaymentInfo.PaymentType === 'Z'" />

        <input
          placeholder="Voornaam"
          name="first-name"
          v-model="input.FirstName"
          required="required"
          class="input-full-grid"
        />
        <input
            class="input-half-grid"
            placeholder="Tussenvoegsel"
            name="surname"
            maxlength="10"
            v-model="input.LastNamePrefix"
        />
        <input
          class="input-full-grid"
          placeholder="Achternaam"
          name="surname"
          v-model="input.LastName"
          required="required"
        />
        <div
            class="error input-full-grid"
            v-if="error.username"
        >Dit emailadres is niet beschikbaar, u kunt inloggen.</div>
        <div
            class="input-half-grid"
            v-if="input.Email"
            id="gebruikersnaam"
        >Gebruikersnaam</div>
        <input
          placeholder="Email"
          class="input-half-grid"
          v-model="input.Email"
          type="email"
          name="username"
          readonly="readonly"
        />

        <div class="error input-half-grid" v-if="error.password">De wachtwoorden komen niet overeen</div>
        <input
          placeholder="Wachtwoord"
          class="input-half-grid"
          type="password"
          v-model="input.Password"
          :class="{'input-error': error.password}"
          required="required"
          v-if="!isHidden"
        />
        <input
          placeholder="Wachtwoord opnieuw"
          class="input-half-grid"
          type="password"
          v-model="input.PasswordExtra"
          :class="{'input-error': error.password}"
          required="required"
          v-if="!isHidden"
        />
        <input
          placeholder="Postcode"
          class="input-quarter-grid"
          v-model="input.ZipCode"
          required="required"
        />
        <input
          type="number"
          placeholder="Huisnummer"
          class="input-quarter-grid"
          v-model="input.HouseNumber"
          required="required"
        />
        <input
          type="text"
          placeholder="Toevoeging"
          class="input-quarter-grid"
          v-model="input.HouseNumberExtra"
        />
        <div class="error input-full-grid" v-if="error.zipcode">Postcode niet correct</div>
        <input class="input-half-grid" v-model="input.Street" placeholder="Straat" :required="true"/>
        <input class="input-half-grid" v-model="input.City" placeholder="Stad" :required="true"/>
        <div class="custom-select input-full-grid">
          <label>
            <select v-model="input.CountryCode">
              <option value="NL">Nederland</option>
              <option value="AF">Afghanistan</option>
              <option value="AX">Åland</option>
              <option value="AL">Albanië</option>
              <option value="DZ">Algerije</option>
              <option value="VI">Amerikaanse Maagdeneilanden</option>
              <option value="AS">Amerikaans-Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AO">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AQ">Antarctica</option>
              <option value="AG">Antigua en Barbuda</option>
              <option value="AR">Argentinië</option>
              <option value="AM">Armenië</option>
              <option value="AW">Aruba</option>
              <option value="AU">Australië</option>
              <option value="AZ">Azerbeidzjan</option>
              <option value="BS">Bahama's</option>
              <option value="BH">Bahrein</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BE">België</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia</option>
              <option value="BA">Bosnië en Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BV">Bouvet</option>
              <option value="BR">Brazilië</option>
              <option value="VG">Britse Maagdeneilanden</option>
              <option value="IO">Brits Indische Oceaanterritorium</option>
              <option value="BN">Brunei</option>
              <option value="BG">Bulgarije</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodja</option>
              <option value="CA">Canada</option>
              <option value="BQ">Caribisch Nederland</option>
              <option value="CF">Centraal-Afrikaanse Republiek</option>
              <option value="CL">Chili</option>
              <option value="CN">China</option>
              <option value="CX">Christmaseiland</option>
              <option value="CC">Cocoseilanden</option>
              <option value="CO">Colombia</option>
              <option value="KM">Comoren</option>
              <option value="CG">Congo-Brazzaville</option>
              <option value="CD">Congo-Kinshasa</option>
              <option value="CK">Cookeilanden</option>
              <option value="CR">Costa Rica</option>
              <option value="CU">Cuba</option>
              <option value="CW">Curaçao</option>
              <option value="CY">Cyprus</option>
              <option value="DK">Denemarken</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominicaanse Republiek</option>
              <option value="DE">Duitsland</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypte</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatoriaal-Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estland</option>
              <option value="ET">Ethiopië</option>
              <option value="FO">Faeröer</option>
              <option value="FK">Falklandeilanden</option>
              <option value="FJ">Fiji</option>
              <option value="PH">Filipijnen</option>
              <option value="FI">Finland</option>
              <option value="FR">Frankrijk</option>
              <option value="TF">Franse Zuidelijke Gebieden</option>
              <option value="GF">Frans-Guyana</option>
              <option value="PF">Frans-Polynesië</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgië</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GD">Grenada</option>
              <option value="GR">Griekenland</option>
              <option value="GL">Groenland</option>
              <option value="GP">Guadeloupe</option>
              <option value="GU">Guam</option>
              <option value="GT">Guatemala</option>
              <option value="GG">Guernsey</option>
              <option value="GN">Guinee</option>
              <option value="GW">Guinee-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haïti</option>
              <option value="HM">Heard en McDonaldeilanden</option>
              <option value="HN">Honduras</option>
              <option value="HU">Hongarije</option>
              <option value="HK">Hongkong</option>
              <option value="IE">Ierland</option>
              <option value="IS">IJsland</option>
              <option value="IN">India</option>
              <option value="ID">Indonesië</option>
              <option value="IQ">Irak</option>
              <option value="IR">Iran</option>
              <option value="IL">Israël</option>
              <option value="IT">Italië</option>
              <option value="CI">Ivoorkust</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="YE">Jemen</option>
              <option value="JE">Jersey</option>
              <option value="JO">Jordanië</option>
              <option value="KY">Kaaimaneilanden</option>
              <option value="CV">Kaapverdië</option>
              <option value="CM">Kameroen</option>
              <option value="KZ">Kazachstan</option>
              <option value="KE">Kenia</option>
              <option value="KG">Kirgizië</option>
              <option value="KI">Kiribati</option>
              <option value="UM">Kleine afgelegen eilanden van de Verenigde Staten</option>
              <option value="KW">Koeweit</option>
              <option value="HR">Kroatië</option>
              <option value="LA">Laos</option>
              <option value="LS">Lesotho</option>
              <option value="LV">Letland</option>
              <option value="LB">Libanon</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libië</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Litouwen</option>
              <option value="LU">Luxemburg</option>
              <option value="MO">Macau</option>
              <option value="MG">Madagaskar</option>
              <option value="MW">Malawi</option>
              <option value="MV">Malediven</option>
              <option value="MY">Maleisië</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="IM">Man</option>
              <option value="MA">Marokko</option>
              <option value="MH">Marshalleilanden</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritanië</option>
              <option value="MU">Mauritius</option>
              <option value="YT">Mayotte</option>
              <option value="MX">Mexico</option>
              <option value="FM">Micronesië</option>
              <option value="MD">Moldavië</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolië</option>
              <option value="ME">Montenegro</option>
              <option value="MS">Montserrat</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Namibië</option>
              <option value="NR">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="NI">Nicaragua</option>
              <option value="NC">Nieuw-Caledonië</option>
              <option value="NZ">Nieuw-Zeeland</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NU">Niue</option>
              <option value="MP">Noordelijke Marianen</option>
              <option value="KP">Noord-Korea</option>
              <option value="MK">Noord-Macedonië</option>
              <option value="NO">Noorwegen</option>
              <option value="NF">Norfolk</option>
              <option value="UG">Oeganda</option>
              <option value="UA">Oekraïne</option>
              <option value="UZ">Oezbekistan</option>
              <option value="OM">Oman</option>
              <option value="AT">Oostenrijk</option>
              <option value="TL">Oost-Timor</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau</option>
              <option value="PS">Palestina</option>
              <option value="PA">Panama</option>
              <option value="PG">Papoea-Nieuw-Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PN">Pitcairneilanden</option>
              <option value="PL">Polen</option>
              <option value="PT">Portugal</option>
              <option value="PR">Puerto Rico</option>
              <option value="QA">Qatar</option>
              <option value="RE">Réunion</option>
              <option value="RO">Roemenië</option>
              <option value="RU">Rusland</option>
              <option value="RW">Rwanda</option>
              <option value="BL">Saint-Barthélemy</option>
              <option value="KN">Saint Kitts en Nevis</option>
              <option value="LC">Saint Lucia</option>
              <option value="PM">Saint-Pierre en Miquelon</option>
              <option value="VC">Saint Vincent en de Grenadines</option>
              <option value="SB">Salomonseilanden</option>
              <option value="WS">Samoa</option>
              <option value="SM">San Marino</option>
              <option value="SA">Saoedi-Arabië</option>
              <option value="ST">Sao Tomé en Principe</option>
              <option value="SN">Senegal</option>
              <option value="RS">Servië</option>
              <option value="SC">Seychellen</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SH">Sint-Helena, Ascension en Tristan da Cunha</option>
              <option value="MF">Saint-Martin</option>
              <option value="SX">Sint Maarten</option>
              <option value="SI">Slovenië</option>
              <option value="SK">Slowakije</option>
              <option value="SD">Soedan</option>
              <option value="SO">Somalië</option>
              <option value="ES">Spanje</option>
              <option value="SJ">Spitsbergen en Jan Mayen</option>
              <option value="LK">Sri Lanka</option>
              <option value="SR">Suriname</option>
              <option value="SZ">Swaziland</option>
              <option value="SY">Syrië</option>
              <option value="TJ">Tadzjikistan</option>
              <option value="TW">Taiwan</option>
              <option value="TZ">Tanzania</option>
              <option value="TH">Thailand</option>
              <option value="TG">Togo</option>
              <option value="TK">Tokelau</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad en Tobago</option>
              <option value="TD">Tsjaad</option>
              <option value="CZ">Tsjechië</option>
              <option value="TN">Tunesië</option>
              <option value="TR">Turkije</option>
              <option value="TM">Turkmenistan</option>
              <option value="TC">Turks- en Caicoseilanden</option>
              <option value="TV">Tuvalu</option>
              <option value="UY">Uruguay</option>
              <option value="VU">Vanuatu</option>
              <option value="VA">Vaticaanstad</option>
              <option value="VE">Venezuela</option>
              <option value="AE">Verenigde Arabische Emiraten</option>
              <option value="US">Verenigde Staten</option>
              <option value="GB">Verenigd Koninkrijk</option>
              <option value="VN">Vietnam</option>
              <option value="WF">Wallis en Futuna</option>
              <option value="EH">Westelijke Sahara/SADR</option>
              <option value="BY">Wit-Rusland</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
              <option value="ZA">Zuid-Afrika</option>
              <option value="GS">Zuid-Georgia en de Zuidelijke Sandwicheilanden</option>
              <option value="KR">Zuid-Korea</option>
              <option value="SS">Zuid-Soedan</option>
              <option value="SE">Zweden</option>
              <option value="CH">Zwitserland</option>
            </select>
          </label>
        </div>
        <div class="custom-select input-quarter-grid">
          <label>
            <select required v-model="input.PhoneNumberPrefix1">
              <option
                v-for="(phone, indexPhone) in phones"
                v-bind:key="indexPhone"
                :value="phone.PhonePrefix"
              >{{phone.CountryName}} ({{phone.PhonePrefix}})</option>
            </select>
          </label>
        </div>
        <input
          placeholder="Telefoonnummer"
          class="input-third-grid"
          type="tel"
          name="phone"
          required="required"
          v-model="input.PhoneNumber1"
        />
        <div
            class="error input-full-grid"
            v-if="error.PhoneNumber1"
        >Telefoonnummer niet correct!</div>
        <div class="custom-select input-quarter-grid">
          <label>
            <select required v-model="input.PhoneNumberPrefix2">
              <option
                v-for="(phone, indexPhone) in phones"
                v-bind:key="indexPhone"
                :value="phone.PhonePrefix"
              >{{phone.CountryName}} ({{phone.PhonePrefix}})</option>
            </select>
          </label>
        </div>
        <input
          placeholder="Telefoonnummer 2 (optioneel)"
          class="input-third-grid"
          type="tel"
          v-model="input.PhoneNumber2"
        />
        <div
            class="error input-full-grid"
            v-if="error.PhoneNumber2"
        >Telefoonnummer niet correct!</div>
        <h4 class="input-full-grid">Veiligheidsvraag</h4>
        <div class="custom-select input-full-grid">
          <label>
            <select required v-model="input.SecurityQuestionNum">
              <option disabled :value="0" selected hidden>Kies een beveiligingsvraag</option>
              <option
                v-for="(question, index) in questions"
                v-bind:key="index"
                :value="question.Id"
              >{{question.Question}}</option>
            </select>
          </label>
        </div>
        <div
            class="error input-full-grid"
            v-if="error.securityQuestion"
        >Beveiligingsvraag is verplicht</div>
        <input
          placeholder="Antwoord"
          class="input-full-grid"
          required
          v-model="input.SecurityQuestionAnswer"
        />
        <h4 class="input-full-grid">
          Alarm contactpersoon 1
          <span class="edit-link" @click="switchAddress1">Aanpassen</span>
        </h4>
        <template v-if="showAddress1">
          <input placeholder="Voornaam" class="input-half-grid" v-model="input.Alarm1.FirstName" />
          <input placeholder="Achternaam" class="input-half-grid" v-model="input.Alarm1.LastName" />
          <div class="custom-select input-quarter-grid">
            <label>
              <select required v-model="input.Alarm1.PhoneNumberPrefix1">
                <option
                  v-for="(phone, indexPhone) in phones"
                  v-bind:key="indexPhone"
                  :value="phone.PhonePrefix"
                >{{phone.CountryName}} ({{phone.PhonePrefix}})</option>
              </select>
            </label>
          </div>
          <input
            placeholder="Telefoonnummer"
            class="input-third-grid"
            v-model="input.Alarm1.PhoneNumber1"
          />
          <div
              class="error input-full-grid"
              v-if="error.Alarm1PhoneNumber1"
          >Telefoonnummer niet correct!</div>
          <div class="custom-select input-quarter-grid">
            <label>
              <select required v-model="input.Alarm1.PhoneNumberPrefix2">
                <option
                  v-for="(phone, indexPhone) in phones"
                  v-bind:key="indexPhone"
                  :value="phone.PhonePrefix"
                >{{phone.CountryName}} ({{phone.PhonePrefix}})</option>
              </select>
            </label>
          </div>
          <input
            placeholder="Telefoonnummer 2 (optioneel)"
            class="input-third-grid"
            v-model="input.Alarm1.PhoneNumber2"
          />
          <div
              class="error input-full-grid"
              v-if="error.Alarm1PhoneNumber2"
          >Telefoonnummer niet correct!</div>
        </template>
        <h4 class="input-full-grid">
          Alarm contactpersoon 2
          <span class="edit-link" @click="switchAddress2">Toevoegen</span>
        </h4>
        <template v-if="showAddress2">
          <input placeholder="Voornaam" class="input-half-grid" v-model="input.Alarm2.FirstName" />
          <input placeholder="Achternaam" class="input-half-grid" v-model="input.Alarm2.LastName" />
          <div class="custom-select input-quarter-grid">
            <label>
              <select required v-model="input.Alarm2.PhoneNumberPrefix1">
                <option
                  v-for="(phone, indexPhone) in phones"
                  v-bind:key="indexPhone"
                  :value="phone.PhonePrefix"
                >{{phone.CountryName}} ({{phone.PhonePrefix}})</option>
              </select>
            </label>
          </div>
          <input
            placeholder="Telefoonnummer"
            class="input-third-grid"
            v-model="input.Alarm2.PhoneNumber1"
          />
          <div
              class="error input-full-grid"
              v-if="error.Alarm2PhoneNumber1"
          >Telefoonnummer niet correct!</div>
          <div class="custom-select input-quarter-grid">
            <label>
              <select required v-model="input.Alarm2.PhoneNumberPrefix2">
                <option
                  v-for="(phone, indexPhone) in phones"
                  v-bind:key="indexPhone"
                  :value="phone.PhonePrefix"
                >{{phone.CountryName}} ({{phone.PhonePrefix}})</option>
              </select>
            </label>
          </div>
          <input
            placeholder="Telefoonnummer 2 (optioneel)"
            class="input-third-grid"
            v-model="input.Alarm2.PhoneNumber2"
          />
          <div
              class="error input-full-grid"
              v-if="error.Alarm2PhoneNumber2"
          >Telefoonnummer niet correct!</div>
        </template>
        <div id="login-footer">
            <button @click="back">Terug</button>

            <button class="button-small input-full-grid next-button">Aanmaken</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  data() {
    return {
      isHidden: false,
      username: "",
      password: "",
      showAddress1: false,
      showAddress2: false,
      cityStreet: "",
      forgotPassword: false,
      usernameForgotPassword: "",

      input: {
        FirstName: "",
        LastNamePrefix: "",
        LastName: "",
        Email: "",
        CompanyName: "",
        Password: "",
        PasswordExtra: "",
        ZipCode: "",
        HouseNumber: "",
        HouseNumberExtra: "",
        PhoneNumber1: "",
        PhoneNumberPrefix1: "+31",
        PhoneNumber2: "",
        PhoneNumberPrefix2: "+31",
        SecurityQuestionNum: 0,
        SecurityQuestionAnswer: "",
        City: "",
        Street: "",
        CountryCode: "NL",
        Alarm1: {
          FirstName: "",
          LastName: "",
          PhoneNumber1: "",
          PhoneNumberPrefix1: "+31",
          PhoneNumber2: "",
          PhoneNumberPrefix2: "+31",
        },
        Alarm2: {
          FirstName: "",
          LastName: "",
          PhoneNumber1: "",
          PhoneNumberPrefix1: "+31",
          PhoneNumber2: "",
          PhoneNumberPrefix2: "+31",
        },
        PaymentInfo: {
          CanEdit: false,
          PaymentType: "P",
          CountryCode: "NL",
          KvkNumber: null,
          VatNumber: null,
          AttentionOf: null,
        },
      },
      error: {
        login: false,
        password: false,
        email: false,
        PhoneNumber1: false,
        PhoneNumber2: false,
        Alarm1PhoneNumber1: false,
        Alarm1PhoneNumber2: false,
        Alarm2PhoneNumber1: false,
        Alarm2PhoneNumber2: false,
        username: false,
        kvkRequired: false,
        kvkInvalid: false,
        btw: false,
        zipcode: false,
        securityQuestion: false,
        paymentType: false,
      },
      questions: [],
      phones: [],
    };
  },
  watch: {
    
    "input.ZipCode": function () {
      if (this.input.HouseNumber !== 0) {
        this.zipCodeFetch();
      }
    },
    "input.HouseNumber": function () {
      if (this.input.ZipCode) {
        this.zipCodeFetch();
      }
    },
    "input.PaymentInfo.KvkNumber": function () {
      if (this.input.PaymentInfo.KvkNumber && this.input.PaymentInfo.KvkNumber.length >= 8 && this.input.PaymentInfo.CountryCode === "NL") {
        this.$api.get(`company?kvk=${this.input.PaymentInfo.KvkNumber}`).catch(function() {

        }).then((response) => {
          if(!response.data)
          {
            this.error.kvkInvalid = true;
          }
          else
          {
            this.error.kvkInvalid = false;
            this.input.CompanyName = response.data.CompanyName;
            this.input.Street = response.data.Street;
            this.input.PaymentInfo.VatNumber = response.data.VatNumber;
            this.input.HouseNumber = response.data.HouseNumber;
            this.input.HouseNumberExtra = response.data.HouseNumberExtra;
            this.input.ZipCode = response.data.ZipCode;
            this.input.City = response.data.City;
            this.input.CountryCode = 'NL';
          }
        });
      }
    },
  },
  created() {
    this.$api.get(`User/Load/${process.env.VUE_APP_TITLE}`).then((response) => {
      this.questions = response.data.Questions;
      this.phones = response.data.Phones;

      // Set up some good defaults for the phone number prefix. If done here, it will apply to demo mode and
      // no data yet scenario's.
      let firstPhone = this.phones.find(() => true);
      this.input.PhoneNumberPrefix1 = this.input.PhoneNumberPrefix1 ? this.input.PhoneNumberPrefix1 : firstPhone.PhonePrefix;
      this.input.PhoneNumberPrefix2 = this.input.PhoneNumberPrefix2 ? this.input.PhoneNumberPrefix2 : firstPhone.PhonePrefix;
      this.input.Alarm1.PhoneNumberPrefix1 = this.input.Alarm1.PhoneNumberPrefix1 ? this.input.Alarm1.PhoneNumberPrefix1 : firstPhone.PhonePrefix;
      this.input.Alarm1.PhoneNumberPrefix2 = this.input.Alarm1.PhoneNumberPrefix2 ? this.input.Alarm1.PhoneNumberPrefix2 : firstPhone.PhonePrefix;
      this.input.Alarm2.PhoneNumberPrefix1 = this.input.Alarm2.PhoneNumberPrefix1 ? this.input.Alarm2.PhoneNumberPrefix1 : firstPhone.PhonePrefix;
      this.input.Alarm2.PhoneNumberPrefix2 = this.input.Alarm2.PhoneNumberPrefix2 ? this.input.Alarm2.PhoneNumberPrefix2 : firstPhone.PhonePrefix;

      // Demo mode: don't load already filled out activation data because we don't have an actual activation.
      if (this.$cookies.get("activation-code") === "demo"){
        return;
      }

      this.$api.get("User/Data").then((response) => {
        if (response.data) {
          this.input = response.data;

          // Set all phone prefix pickers to the first available phone prefix in the list. This will set them all
          // to Netherlands, which is the default anyway. Please note that there is data about the user, but if some
          // phone numbers aren't known, the prefix will be empty unless we do this. That is because the input property
          // is overwritten completely.
          let firstPhone = this.phones.find(() => true);
          this.input.PhoneNumberPrefix1 = this.input.PhoneNumberPrefix1 ? this.input.PhoneNumberPrefix1 : firstPhone.PhonePrefix;
          this.input.PhoneNumberPrefix2 = this.input.PhoneNumberPrefix2 ? this.input.PhoneNumberPrefix2 : firstPhone.PhonePrefix;
          this.input.Alarm1.PhoneNumberPrefix1 = this.input.Alarm1.PhoneNumberPrefix1 ? this.input.Alarm1.PhoneNumberPrefix1 : firstPhone.PhonePrefix;
          this.input.Alarm1.PhoneNumberPrefix2 = this.input.Alarm1.PhoneNumberPrefix2 ? this.input.Alarm1.PhoneNumberPrefix2 : firstPhone.PhonePrefix;
          this.input.Alarm2.PhoneNumberPrefix1 = this.input.Alarm2.PhoneNumberPrefix1 ? this.input.Alarm2.PhoneNumberPrefix1 : firstPhone.PhonePrefix;
          this.input.Alarm2.PhoneNumberPrefix2 = this.input.Alarm2.PhoneNumberPrefix2 ? this.input.Alarm2.PhoneNumberPrefix2 : firstPhone.PhonePrefix;
        }
      });
    });
   
    if(process.env.VUE_APP_TITLE =='loqater') {
      
      this.isHidden = true;
    }
    
    this.$emit("changeStep", 1);
  },
  
  methods: {
		back() {
			this.$router.push("/Vehicle");
		},
    login() {
      this.error.login = false;
      if (this.username && this.password) {
        // Demo mode: don't actually log in. Just skip this page.
        if (this.$cookies.get("activation-code") === "demo"){
          this.$router.push("/plan");
          return;
        }

        this.$api
          .post("User/Login", {
            Username: this.username,
            Password: this.password,
          })
          .then(() => {
            this.$router.push("/plan");
          })
          .catch(() => {
            this.error.login = true;
          });
      }
    },
    async signup() {
      //Validation
      if (!this.validateEmail(this.input.Email)) {
        this.error.email = true;
      } else {
        this.error.email = false;
      }
      if(this.input.SecurityQuestionNum === 0)
      {
        this.error.securityQuestion = true;
      }
      else
      {
        this.error.securityQuestion = false;
      }

      this.error.zipcode = !this.validateZipcode(this.input.CountryCode, this.input.ZipCode);

      if (this.input.Password !== this.input.PasswordExtra && !this.isHidden) {
        this.error.password = true;
      } else {
        this.error.password = false;
      }

      if (this.input.PaymentInfo.PaymentType === 'Z' || this.input.PaymentInfo.PaymentType === 'P') {
        this.error.paymentType = false;
      } else {
        this.error.paymentType = true;
      }

      this.error.kvkRequired = false;
      this.error.kvkInvalid = false;
      if (this.input.PaymentInfo.PaymentType === 'Z' && this.input.PaymentInfo.CountryCode === "NL") {
        if (!this.input.PaymentInfo.KvkNumber ||
            !this.input.PaymentInfo.KvkNumber.trim() ||
            this.input.PaymentInfo.KvkNumber.includes('-')) {
          this.error.kvkRequired = true;
        } else if (!this.input.CompanyName) {
          this.error.kvkInvalid = true;
        }

        if (!this.input.PaymentInfo.VatNumber ||
            !this.input.PaymentInfo.VatNumber.trim() ||
            this.input.PaymentInfo.VatNumber.includes("-")) {
          this.error.btw = true;
        }
      }

      try {
        // Demo mode: don't check user names.
        if (this.$cookies.get("activation-code") === "demo"){
          // Do nothing.
        }
        else{
          await this.$api.post(`User/Username`, {"email": this.input.Email});
        }
        this.error.username = false;
      } catch {
        this.error.username = true;
      }

      this.input.PhoneNumber1 = this.input.PhoneNumber1.replace(/^0+/, '');
      this.input.PhoneNumber2 = this.input.PhoneNumber2 ? this.input.PhoneNumber2.replace(/^0+/, '') : '';
      this.input.Alarm1.PhoneNumber1 = this.input.Alarm1.PhoneNumber1.replace(/^0+/, '');
      this.input.Alarm1.PhoneNumber2 = this.input.Alarm1.PhoneNumber2.replace(/^0+/, '');
      this.input.Alarm2.PhoneNumber1 = this.input.Alarm2.PhoneNumber1.replace(/^0+/, '');
      this.input.Alarm2.PhoneNumber2 = this.input.Alarm2.PhoneNumber2.replace(/^0+/, '');

      if(this.isHidden){
        this.input.Password = "Loqater2024!";
        this.input.PasswordExtra = "Loqater2024!";
      }

      this.error.PhoneNumber1 = this.input.PhoneNumberPrefix1 === "+31" && this.input.PhoneNumber1.length !== 9;
      this.error.PhoneNumber2 = this.input.PhoneNumberPrefix2 === "+31" && (this.input.PhoneNumber2.length !== 9 && this.input.PhoneNumber2.length !== 0);
      this.error.Alarm1PhoneNumber1 = this.input.Alarm1.PhoneNumberPrefix1 === "+31" && this.input.Alarm1.PhoneNumber1.length !== 9 && this.input.Alarm1.PhoneNumber1.length !== 0;
      this.error.Alarm1PhoneNumber2 = this.input.Alarm1.PhoneNumberPrefix2 === "+31" && this.input.Alarm1.PhoneNumber2.length !== 9 && this.input.Alarm1.PhoneNumber2.length !== 0;
      this.error.Alarm2PhoneNumber1 = this.input.Alarm2.PhoneNumberPrefix1 === "+31" && this.input.Alarm2.PhoneNumber1.length !== 9 && this.input.Alarm2.PhoneNumber1.length !== 0;
      this.error.Alarm2PhoneNumber2 = this.input.Alarm2.PhoneNumberPrefix2 === "+31" && this.input.Alarm2.PhoneNumber2.length !== 9 && this.input.Alarm2.PhoneNumber2.length !== 0;

      if (this.error.email ||
          this.error.password ||
          this.error.username ||
          this.error.zipcode ||
          this.error.kvkInvalid ||
          this.error.kvkRequired ||
          this.error.btw ||
          this.error.securityQuestion ||
          this.error.paymentType ||
          this.error.PhoneNumber1 ||
          this.error.PhoneNumber2 ||
          this.error.Alarm1PhoneNumber1 ||
          this.error.Alarm1PhoneNumber2 ||
          this.error.Alarm2PhoneNumber1 ||
          this.error.Alarm2PhoneNumber2
      ) {
        return;
      }

      // Demo mode: don't save, just go to the next page.
      if (this.$cookies.get("activation-code") === "demo"){
        this.$router.push("/plan");
        return;
      }

      this.$api.post("User/Signup", this.input).then(() => {
        this.$router.push("/plan");
      });
    },
    forgotPasswordSwitch() {
      this.forgotPassword = !this.forgotPassword;
    },
    forgotPasswordSubmit() {
      // Demo mode: do nothing.
      if (this.$cookies.get("activation-code") === "demo"){
        return;
      }

      this.$api.post("User/Password", {
        UserName: this.usernameForgotPassword,
        Whitelabel: process.env.VUE_APP_TITLE,
      });
    },
    gotoSignup() {
      setTimeout(() => {
        this.$refs.signupBlock.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 2);
    },
    switchAddress1() {
      this.showAddress1 = !this.showAddress1;
    },
    switchAddress2() {
      this.showAddress2 = !this.showAddress2;
    },
    zipCodeFetch() {
        if (this.input.ZipCode && this.input.ZipCode.length >= 6)
      {
        let config = {
          headers: {
            "X-Api-Key": "jFgjIIXze760eSWZIVhbLj1xIFPF07o5rc6eVI8g",
          },
        };
        Axios.get(
            `https://postcode-api.apiwise.nl/v2/addresses/?postcode=${this.input.ZipCode}`,
            config
        ).then((response) => {
          var house = response.data._embedded.addresses.find(
              (i) => i.number == this.input.HouseNumber
          );
          if (house) {
            this.input.Street = this.input.Street ? this.input.Street : house.street;
            this.input.City = this.input.City ? this.input.City : house.city.label;
          }
        });
      }
    },
    validateEmail(email) {
      /*eslint-disable */
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
      /*eslint-enable */
    },
    validateZipcode(countryCode, zipcode) {
      if(countryCode !== "NL")
      {
        return true;
      }
      /*eslint-disable */
      var re = /^[0-9]{4}\s?[a-zA-Z]{2}$/;
      return re.test(String(zipcode));
      /*eslint-enable */
    },
  },
};

</script>

<style scoped lang="scss">
#signin-block {
  width: 400px;
  min-width: 400px;
  background-color: $primary;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#signin-block-inner {
  padding: 25px 60px;
}

#signup-block {
  padding: 30px 60px;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  flex-grow: 1;
}

#signup-block-inner {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 20px;
  margin: 20px 0;
}

.new-customer {
  display: none !important;
}

@media only screen and (max-width: 1199px) {
  #signin-block {
    height: calc(100vh - 77px);
  }

  #signup-block {
    height: calc(100vh - 77px);
  }
}

@media only screen and (max-width: 1000px) {
  #content-main {
    flex-wrap: wrap;
  }
  #signup-block {
    overflow: visible;
  }
  .new-customer {
    display: block !important;
  }
}

@media only screen and (max-width: 823px) {
  #signin-block-inner {
    padding: 25px;
  }

  #signup-block {
    padding: 25px;
  }

  #signin-block {
    width: 100%;
  }
}

.input-1-grid {
  grid-column: span 1;
}

.input-7-grid {
  grid-column: span 7;
}

.input-quarter-grid {
  grid-column: span 2;
}

.input-half-grid {
  grid-column: span 4;
}

.input-third-grid {
  grid-column: span 6;
}

.input-full-grid {
  grid-column: span 8;
}

#input-lastname {
  grid-column: span 4;
}

#signup-head {
  grid-column: 1 / span 8;
}

#signin-head {
  font-weight: 300;
  margin-bottom: 20px;
}

#signup-block h3 {
  font-weight: bold;
}

.next-button {
	background-color: $secondary;
	color: $secondary-button;
	border: none;
}

.next-button:hover {
	background-color: $secondary-light;
}

.login-button {
  width: 100%;
  background-color: $secondary;
	color: $secondary-button;
  margin-top: 15px;
}

.login-button:hover {
  background-color: $secondary;
}

a,
a:visited {
  color: $secondary;
  text-decoration: none;
  text-align: center;
}

.center-link {
  display: block;
  margin-top: 10px;
}

input:disabled {
  border: none !important;
}

.edit-link {
  color: $secondary;
  cursor: pointer;
  margin-left: 20px;
  font-size: 11pt;
  align-self: center;
}

.custom-select:after {
  content: "";
  font-size: 17px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 11px;
  /*Adjust for position however you want*/

  top: 18px;
  padding: 0 0 2px;
  border-bottom: 1px solid $primary;
  /*left line */

  position: absolute;
  pointer-events: none;
}

.custom-select select { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  height: 37px;
  padding: 0px 5px;
  color: $primary;
  background-color: $white;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
  border: none;
  border-bottom: 1px solid $primary;
  width: 100%;
}

.mfselect {
  display: grid;
  grid-gap: 10px;
}

.custom-select select:focus {
  outline: none;
}

.custom-select select:required:invalid {
  color: $dark-gray;
}

#login-footer {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
  grid-column: span 8;
}

#login-footer > * {
	align-self: flex-end;
}
#gebruikersnaam {
  margin: auto 0;
  font-size: 13.3333px;
  color: $dark-gray;
  font-family: "Arial";
  padding-left: 5px;
}
</style>
